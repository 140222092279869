import React from "react"
import { Link } from "gatsby" 

export const Estate = ({estate}) => {

    const image = estate?.files[0]?.filename;
     
    return (
        <Link  to={'/nemovitost/' + estate.alias} className=" w-full p-3">
          <div className="relative group flex flex-col  text-center sm:border-0  rounded-md border overflow-hidden w-full">
            {image ? <div className=" h-60 overflow-hidden w-full"><img src={image} alt={estate.name}  className=" transform  group-hover:scale-110 ease-in-out duration-150 object-contain w-full " /></div> : <div className=" h-60 overflow-hidden w-full"></div> }
            <div className="  px-4 block  py-3 font-medium  text-gray-900 border border-gray-300  absolute    bottom-0 left-0 right-0 rounded-b-md backdrop-filter  w-full bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
              {estate.name}</div>  

          </div>
        </Link>
    )
  }
    