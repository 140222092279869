import React from "react"
import { ESTATE_LIST } from "../../query/estate"
import { useQuery } from "@apollo/client"
import { Estate } from "./Estate"
import { Loading } from "../layout/Loading"

export const Estates = () => {
  const { loading, error, data } = useQuery(ESTATE_LIST, {
    fetchPolicy: "cache-and-network",
  })

  return (
    <>
      {loading && <Loading loading={true} />}

      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto ">
              <div className="rounded-lg  grid  md:grid-cols-3 justify-items-center  overflow-hidden">
                {data &&
                  data.estates.map((estate, i) => (
                    <Estate key={i} estate={estate} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
