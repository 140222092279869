import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Cafe } from "../components/elements/Cafe"
import { Estates } from "../components/estate/Estates"

const KatalogPage = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Katalog nemovitostí | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        <div className=" max-w-7xl mx-auto pt-12 sm:pt-16">
          <div className=" px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center  ">
              <h2 className="text-2xl inset-0 sm:text-4xl">
                <span className="font-extrabold">Katalog</span> nemovitostí
              </h2>
            </div>
          </div>
          <Estates />
          <Cafe />
        </div>
      </main>
    </>
  )
}

export default KatalogPage
