import { MailIcon, PhoneIcon } from "@heroicons/react/solid"
import React from "react"
export const Cafe = () => {
  return (
    <>
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
              Kávu platíme my
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pomáháme firmám i jednotlivcům
            </p>
            <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
              K vašim potřebám přistoupíme individuálně. Je to jednoduché,
              zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
              zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
            </p>
          </div>

          <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
            <div className="flex lg:justify-center items-center">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                <PhoneIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <p className="ml-3 text-xl  font-medium text-gray-900">
                800 991 100
              </p>
            </div>
            <div className="flex lg:justify-center items-center">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                <MailIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <a
                href="mailto:office@malgen.cz"
                className="ml-3 text-xl  font-medium text-gray-900"
              >
                office@malgen.cz
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
